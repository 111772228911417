import React from "react";
import './navBar.css';

function NavigationBar(props) {

  return (

    <nav className="navbar navbar-expand-lg navbar-light custom-bar">
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNavDropdown">

        <ul className="navbar-nav custom-center">
          
          <li className="nav-item active">
            <a className="nav-link text-white" href="/getacashoffertoday">Get A Cash Offer Today</a>
          </li>
          <li className="nav-item">
            <a className="nav-link text-white" href="/howitworks">How it Works</a>
          </li>

          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle text-white" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Resources</a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <a className="dropdown-item" href="avoidmlslisting">Avoid MLS Listing</a>
              <a className="dropdown-item" href="FAQs">Frequently Asked Questions</a>
            </div>
          </li>
          {/* <li className="nav-item active">
            <a className="nav-link text-white" href="/cashBuyers">Cash Buyers</a>
          </li> */}
          <li className="nav-item">
            <a className="nav-link text-white" href="/contactUs">Contact Us</a>
          </li>
        </ul>

      </div>
    </nav>
  )
}

export default NavigationBar;
