import React, { Component } from "react";
import Wrapper from "../../components/Wrapper";
import Footer from "../../components/Footer";
import ReactPixel from "react-facebook-pixel";

class FAQs extends Component {

    componentDidMount() {

        ReactPixel.init("373240763620383", {}, { debug: true, autoConfig: false });
        ReactPixel.pageView();
        ReactPixel.fbq("track", "PageView");
    }

    render() {
        return (
            <Wrapper>

                <div className="container-fluid">

                    <div className="row mt-5 mb-2">
                        <div className="col-md-8 offset-md-2">
                            <h1> Here Are Our Most Frequently Asked Questions:</h1>

                            <p>We always want to help our local community by answering any questions they may have.
                                Below is a collection of the most frequently asked questions we receive from home owners in the Atlanta Metro Area.
                                If you have any more questions that you can not find here please feel free to give us a call at (404) 793-4464 or contact us by filling out this simple form.</p>
                        </div>
                    </div>
                    <div className="col-md-8 offset-md-2 mb-5">

                        <div className="accordion" id="accordionExample">
                            <div className="card">
                                <div className="card-header custom-box" id="headingOne">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed text-white" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <strong>Do you pay cash when you buy my house?</strong>
                                        </button>
                                    </h5>
                                </div>

                                <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                    <div className="card-body bg-white">
                                        <p>Absolutely! We can pay cash when we buy your house. We have bought several houses for cash in our local area.
                                            Another great benefit when you sell to us is that we also offer several other ways to buy your house. This is great for all of our customers because it allows us to use creative financing.
                                            That means we can put even more money in your pockets!</p>

                                        <p>If you are curious to learn how much we can offer for your house or would like to hear more about our creative financing,
                                            then <a href="/getacashoffertoday">fill out this simple form</a> or give us a <strong>call at (404) 793-4464</strong>.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header custom-box" id="headingTwo">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed text-white" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            <strong>Are there any costs when we sell to you?</strong>
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                    <div className="card-body">
                                        When you sell a house the traditional way...
                                        You soon discover that there are thousands of dollars in repairs, fees, and costs. One of the greatest reasons you should sell to us is that <strong>you pay
                                        absolutely no costs!</strong> When we buy your house we take care of all the expenses. This means you get to walk away with <strong>more cash in your pockets.</strong>
                                        It’s our goal to make the entire process as easy as possible. We want to make sure you have a <strong>Hassle Free</strong> and <strong>Pain Free</strong> home selling experience!
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header custom-box" id="headingThree">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed text-white" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            <strong>How much can you buy my house for?</strong>
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                    <div className="card-body">
                                        <p>Our process for creating an offer is very simple. We take a look at the location of your house,
                                        what kind of repairs are needed in order to bring the house to acceptable standards, and how much similar houses have sold for in the past few months.
                                        After we determine these 3 factors, we’re able to give you a <strong>FAIR cash offer</strong> for your house.</p>

                                        <p>We try and be as open as possible with everyone. If you have any questions about how we can buy your house...
                                        Please feel free to give us a call at <strong>(404) 793-4464</strong> or <a href="/getacashoffertoday">fill out this simple form</a>.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header custom-box" id="headingFour">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed text-white" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            <strong>How long will it take to sell my house?</strong>
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p>The simple answer is “it depends”.
                                            We have bought several houses over the years.
                                            This has allowed us to create a great house buying process.
                                            We can even <strong>buy your house in as little as 30 days!</strong></p>

                                        <p>However, not every house can be sold in 30 days.
                                            Different homeowners experience different situations.
                                            Some situations are more serious than others. The best part about selling to Prominent Elite Ventures is that we’ve <strong>purchased several
                                            homes</strong> in our local area and have been able to deal with all sorts of difficulties.
                                            When you sell to us you can be sure that we will do anything we can to <strong>buy your house as quickly as possible!</strong></p>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header custom-box" id="headingFive">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed text-white" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            <strong>What if I owe money on my house?</strong>
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                    <div className="card-body">
                                        <p>We can still buy your house even if you still have loans or liens on your house.
                                            If you owe more than what our cash offer is, that is still ok. We have been able to <strong>buy several homes with high
                                            mortgages</strong> by taking advantage of our incredible special financing offers.</p>

                                        <p>There is no situation we do not want to help with.
                                            If you want to sell your house even with loans or liens,
                                            then give us a call at <strong>(404) 793-4464</strong> or contact us by <a href="/getacashoffertoday">filling out this simple form</a>.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header custom-box" id="headingSix">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed text-white" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                            <strong>Can you buy my house if it is listed with an agent?</strong>
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
                                    <div className="card-body">
                                        <p>Unfortunately, we can not. When you list your house with an agent <strong>the house becomes contracted</strong> and we are not able to buy it.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header custom-box" id="headingSeven">
                                    <h5 className="mb-0">
                                        <button className="btn btn-link collapsed text-white" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                            <strong>Does you FREE cash offer put me under any obligation?</strong>
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample">
                                    <div className="card-body">
                                        <p><u>Absolutely not!</u> Every single one of our <strong>FREE Cash Offers</strong> is risk free. You are under no obligation to us when you receive our offer.
                                            It is completely your choice to accept or decline our offer when we present it to you.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <Footer />
            </Wrapper >
        )
    }
}

export default FAQs;
