import React, { Component } from "react";
import Wrapper from "../../components/Wrapper";
import Footer from "../../components/Footer";
import ReactPixel from "react-facebook-pixel";

class HowItWorksPage extends Component {

    componentDidMount() {

        ReactPixel.init("373240763620383", {}, { debug: true, autoConfig: false });
        ReactPixel.pageView();
        ReactPixel.fbq("track", "PageView");
    }

    render() {
        return (
            <Wrapper>
                <div className="container-fluid">

                    <div className="row mt-5 mb-2">
                        <div className="col-md-11 offset-md-1">
                            <h1>Here Is Our Step-By-Step House Buying Process:</h1>
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div className="col-md-11 offset-md-1">
                            <img style={{ float: "left" }} src={"/img/house-icon.jpg"} />
                            <div class="content-heading"><h3>&emsp;Step 1: Tell us a little bit about your house</h3></div>
                            <p>Before we buy your house we will just need to gather a bit of information in order for us to make you the best cash offer.
                                    All it takes is submitting our simple form or giving us a call at (404) 793-4464.</p>
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div className="col-md-11 offset-md-1">
                            <img style={{ float: "left" }} src={"/img/house-icon.jpg"} />
                            <div class="content-heading"><h3>&emsp;Step 2: Schedule An Appointment</h3></div>
                            <p>We will schedule an appointment to ask you a few more questions about the house so that we can put together a fair cash offer that works for the both of us.</p>
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div className="col-md-11 offset-md-1">
                            <img style={{ float: "left" }} src={"/img/house-icon.jpg"} />
                            <div class="content-heading"><h3>&emsp;Step 3: Get A FREE No-Obligation Offer</h3></div>
                            <p>We guarantee to give you a fair cash offer to buy your house as-is (no matter what condition it’s in).
                                    You’ll never have to worry about paying fees, commissions, or repairs.</p>
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div className="col-md-11 offset-md-1">
                            <img style={{ float: "left" }} src={"/img/house-icon.jpg"} />
                            <div class="content-heading"><h3>&emsp;Step 4: We Open Escrow</h3></div>
                            <p>After you accept our fair cash offer, we will open escrow & get started right away so that we can close on your property quickly!</p>
                        </div>
                    </div>

                    <div class="row mt-5 mb-5">
                        <div className="col-md-11 offset-md-1">
                            <img style={{ float: "left" }} src={"/img/house-icon.jpg"} />
                            <div class="content-heading"><h3>&emsp;Step 5: You Cash Your Check!</h3></div>
                            <p>The last and most important step is you receiving your check for your house!
                                    We love helping as many people in our local community as possible, and buying homes for top cash value is one of the ways we do it!</p>
                        </div>
                    </div>

                    <div className="row bg-dark mb-4">

                        <div className="col-md-11 offset-md-1 text-white pt-4 pb-4">
                            <h1>We Make Selling Your House As Easy As Possible!</h1>
                            <p>Prominent Elite Ventures specializes in buying houses in the local Atlanta Metro Area.
                                We understand that selling a house can be a very nerve-wracking and overwhelming task, but you never have to worry about a thing when you sell to us.
                               We take care of EVERYTHING!</p>

                            <p>Forget about finding the right agent, dealing with annoying repairs, and leaving your house to sit on the market all just to find out that it won’t sell.
                            We make sure that you never have to deal with that. We’re not looking to sell your house for you... <strong>WE WANT TO BUY IT!</strong></p>

                            <p>If you’re ready to get cash for your house, then get started by clicking below...
                                All it takes is filling out a simple form or giving us a quick call at <strong>(404) 793-4464</strong>.</p>
                        </div>
                    </div>
                </div>
                <Footer />
            </Wrapper >
        )
    }
}

export default HowItWorksPage;
