import React from 'react'

export const Buyers = ({ buyers }) => {

    const BuyerRow = (buyer, index) => {

        console.log(buyers)

        return (
            <tr key={index} className='even'>
                <td> {index + 1} </td>
                <td>{buyer.firstName}</td>
                <td>{buyer.lastName}</td>
                <td>{buyer.email}</td>
                <td>{buyer.mobile}</td>
                <td>{buyer.buyer}</td>
                <td>{buyer.singleFamily === true ? 'Yes': ''}</td>
                <td>{buyer.multiFamily === true ? 'Yes': ''}</td>
                <td>{buyer.commercial === true ? 'Yes': ''}</td>
                <td>{buyer.twoFifty === true ? 'Yes': ''}</td>
                <td>{buyer.fiveHundred === true ? 'Yes': ''}</td>
                <td>{buyer.sevenFifity === true ? 'Yes': ''}</td>
                <td>{buyer.oneMillion === true ? 'Yes': ''}</td>
                <td>{buyer.oneMillionPlus === true ? 'Yes': ''}</td>
            </tr>
        )
    }

    const BuyerTable = buyers.map((cust, index) => BuyerRow(cust, index))

    const tableHeader = <thead className='bgvi'>
        <tr>
            <th>#</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Mobile</th>
            <th>Buyer</th>
            <th>Single Family</th>
            <th>Multi-Family</th>
            <th>Commercial</th>
            <th>250k</th>
            <th>500k</th>
            <th>750k</th>
            <th>1 Million</th>
            <th>1 Million Plus</th>
        </tr>
    </thead>

    return (
        <table className="table table-striped table-bordered table-sm" id="buyers-xls">
            {tableHeader}
            <tbody>
                {BuyerTable}
            </tbody>
        </table>
    )
}
