import React, { Component } from "react";
import Wrapper from "./components/Wrapper";
import LogoHeader from "./components/LogoHeader";
import NavigationBar from "./components/NavigationBar";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./pages/Home-Page";
import CashOffer from "./pages/Cash-Offer-Page";
import HowItWorks from "./pages/How-It-Works-Page";
import AvoidMLSListing from "./pages/Avoid-MLS-Listing-Page";
import FAQs from "./pages/FAQs-Page";
import CashBuyers from "./pages/Cash-Buyers-Page";
import ContactUs from "./pages/Contact-Us-Page";
import TermsOfService from "./pages/Terms-Of-Service-Page";
import PrivacyPolicy from "./pages/Privacy-Policy";
import Admin from "./pages/Admin-Page";
import ContactTable from "./pages/Contact-Table-Page";
import { PrivateRoute } from "./utils/PrivateRoute";
import noMatch from "./pages/No-Match";

class App extends Component {

  render() {
    return (
      <>
        <LogoHeader />
        <NavigationBar />
        <Router>
          <Wrapper>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/getacashoffertoday" component={CashOffer} />
              <Route exact path="/howitworks" component={HowItWorks} />
              <Route exact path="/avoidmlslisting" component={AvoidMLSListing} />
              <Route exact path="/faqs" component={FAQs} />
              <Route exact path="/cashBuyers" component={CashBuyers} />
              <Route exact path="/contactUs" component={ContactUs} />
              <Route exact path="/termsOfService" component={TermsOfService} />
              <Route exact path="/privacyPolicy" component={PrivacyPolicy} />

              <Route exact path="/admin" component={Admin} />
              <PrivateRoute exact path="/contactTable" component={ContactTable} />

              <Route component={noMatch} />
            </Switch>
            
          </Wrapper>
        </Router>
      </>
    );
  }
}

export default App;
