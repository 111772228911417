import React from "react";
import '../jumbotron.css';


function FormSubmitted() {
    return <div className="submittedBox">
                <img src={"/img/green-check.jpg"} alt="Smiley face" width="30" height="30" align="middle"/>&emsp;Your contact information has been submitted.
            </div>
        }
        
export default FormSubmitted;
