import React, { Component } from "react";
import FormSubmitted from "../FormSubmitted";
import Firebase from "../../utils/Firebase";
import '../jumbotron.css';
import ReactPixel from "react-facebook-pixel";

class Jumbotron extends Component {

    componentDidMount() {

        ReactPixel.init("373240763620383", {}, { debug: true, autoConfig: false });
    }

    state = {
        fname: "",
        lname: "",
        address: "",
        mobile: "",
        email: "",
        showSubmitted: false
    };

    onFocus = event => {

        this.setState({
            showSubmitted: false
        })
    }

    handleInputChange = event => {

        this.setState({ [event.target.name]: event.target.value });
    }

    handleFormSubmit = event => {
        event.preventDefault();

        event.target.className += " was-validated";

        if (event.target.checkValidity()) {

            Firebase
                .firestore()
                .collection('Sellers')
                .add({
                    firstName: this.state.fname,
                    lastName: this.state.lname,
                    address: this.state.address,
                    mobile: this.state.mobile,
                    email: this.state.email
                })
                .then(() => {

                    this.setState({
                        fname: "",
                        lname: "",
                        address: "",
                        mobile: "",
                        email: "",
                        showSubmitted: true
                    })
                })

            event.target.className -= " was-validated";

            ReactPixel.trackCustom('HomePage_Bottom_Seller_Form');
        }
    }

    render() {
        return (
            <div className="jumbotron bottom-jumbotron">
                <div className="container">

                    <div className="row justify-content-md-center">

                        <div className="formBackground">
                            <h3>We buy houses for cash fast!</h3>
                            If you’ve been looking to sell a house fast in the Atlanta Metro Area, give us a call at (404) 793-4464 or fill out our simple form below and we can give you a fast and fair cash offer. What do you have to lose? Call us today!
                        <div className="mt-4">
                                {this.state.showSubmitted ? <FormSubmitted /> : null}
                            </div>

                            <form className="needs-validation" noValidate onSubmit={this.handleFormSubmit}>
                                <div className="form-row mt-4">
                                    <div className="form-group col-md-6">

                                        <input type="text"
                                            name="fname"
                                            value={this.state.fname}
                                            onChange={this.handleInputChange}
                                            onFocus={this.onFocus}
                                            className="form-control"
                                            id="inputFirstName"
                                            placeholder="First Name"
                                            required
                                        />
                                        <div className="invalid-feedback">
                                            Please provide your first name.
                                        </div>
                                    </div>

                                    <div className="form-group col-md-6">
                                        <input type="text"
                                            name="lname"
                                            value={this.state.lname}
                                            onChange={this.handleInputChange}
                                            onFocus={this.onFocus}
                                            className="form-control"
                                            id="inputLastName"
                                            placeholder="Last Name"
                                            required
                                        />
                                        <div className="invalid-feedback">
                                            Please provide your last name.
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input type="text"
                                        name="address"
                                        value={this.state.address}
                                        onChange={this.handleInputChange}
                                        onFocus={this.onFocus}
                                        className="form-control"
                                        id="inputAddress"
                                        placeholder="Property Address"
                                        required
                                    />
                                    <div className="invalid-feedback">
                                        Please provide the address that is for sale.
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <input type="text"
                                            name="mobile"
                                            value={this.state.mobile}
                                            onChange={this.handleInputChange}
                                            onFocus={this.onFocus}
                                            className="form-control"
                                            id="inputMobile"
                                            placeholder="Mobile Number"
                                            required
                                        />
                                        <div className="invalid-feedback">
                                            Please provide your mobile number.
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <input type="email"
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.handleInputChange}
                                            onFocus={this.onFocus}
                                            className="form-control"
                                            id="inputEmail"
                                            placeholder="E-mail Address"
                                            required
                                        />
                                        <div className="invalid-feedback">
                                            Please provide your email address.
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" className="needs-validation"
                                    className="btn btn-custom">
                                    Get A Fair Cash Offer Now!
                                    </button>
                                <small className="form-text mt-3 text-primary">
                                    We'll never share your email or mobile number with anyone.
                                </small>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Jumbotron;
