import React, { Component } from "react";
import Wrapper from "../../components/Wrapper";
import Footer from "../../components/Footer";
import ReactPixel from "react-facebook-pixel";

class AvoidMLSListing extends Component {

    componentDidMount() {

        ReactPixel.init("373240763620383", {}, { debug: true, autoConfig: false });
        ReactPixel.pageView();
        ReactPixel.fbq("track", "PageView");
    }

    render() {
        return (
            <Wrapper>
                <div className="container-fluid">

                    <div className="row mt-5 mb-2">
                        <div className="col-md-11 offset-md-2">
                            <h1>Why Sell To Us Instead Of Listing With A Local Real Estate Agent:</h1>
                        </div>
                    </div>

                    <div className="row mt-5 mb-2">
                        <div className="col-md-8 offset-md-2">
                            <p>We want to share with you the main differences between listing your house on the market with an agent and selling your house to us...
                                The important thing to remember is that when selling to an agent you will have a higher sales price,
                                but a lot of times homeowners do not realize the amount of fees, costs and commissions that go along with selling the traditional route.
                                Below we have outlined a complete breakdown of the major differences between selling to us and listing with an agent.</p>
                        </div>
                    </div>


                    <div className="row justify-content-md-center pt-3">
                        <div className="left-box">

                            <h4><b>Selling Your House To Us:</b></h4>

                            <ul className="list-green-check-group pt-3">
                                <li><h4>We Pay All The Closing Costs!</h4></li>
                                <li><h4>You Pay Absolutely No Commissions!</h4></li>
                                <li><h4>We Pay For All The Repairs!</h4></li>
                                <li><h4>You Never Have To Deal With Showings!</h4></li>
                                <li><h4>We Can Buy In As Little As 30 Days!</h4></li>
                                <li><h4>Close On The Date Of Your Choice!</h4></li>
                            </ul>
                        </div>

                        <div className="right-box">

                            <h4><b>Selling Your House To Us:</b></h4>

                            <ul className="list-red-x-group pt-3">
                                <li><h4>Seller Pays Around 2% of Closing Costs</h4></li>
                                <li><h4>An Average Of 6% Commissions Is Paid To The Agent</h4></li>
                                <li><h4>May Be Stuck With Repairs To Bring House To Livable Conditions</h4></li>
                                <li><h4>Have To Deal With Agents Showing Your House To Different Buyers</h4></li>
                                <li><h4>May Take Up To 90 Days!</h4></li>
                                <li><h4>Close When Your Buyer Is Ready</h4></li>
                            </ul>
                        </div>
                    </div>

                    <div className="row bg-dark mb-4">

                        <div className="col-md-8 offset-md-2 text-white pt-4 pb-4">
                            <p>As you can clearly see there are a few things to worry about when selling your house with an agent...
                                We make it so that you don’t have to deal with any of those annoyances! Our process is easy and simple.</p>
                            <br/>

                            <h2>You Pay No Fees, Repairs, or Commissions… Period.</h2>
                            <p>You see, we are not listing your house. We are actually going to buy it. There is no middle man when selling your house to us.
                                Now, we may not be able to offer you full retail value, but we will definitely give you a fair cash offer! And as you now know...
                                Even when you sell your house through an agent for full retail, you won’t be keeping all of that money.</p>
                            <br/>

                            <h2>Sell Your House As Is!</h2>
                            <p>When you sell to us you never have to worry about repairing the house. We buy house no matter what condition they are in.
                                When you sell your house through an agent, however, there are some standards that your house must meet before anyone can buy it.
                                This means that in order to sell your house you may have to come out of pocket and do any necessary repairs. Avoid the headaches and sell to us.
                                We can give you a <a href="/getacashoffertoday">FREE No-Obligation cash offer</a> no matter what your house looks like!</p>
                            <br/>
                            
                            <h2>Sell When You Want!</h2>
                            <p>Did you know that on average a house will sit on the market for 90 days! Can you wait 3 months for your house to sell?
                                When you sell to us you won’t have to… We can actually buy your house in as little as 30 days! What’s even better is that you get to decide when you want to close.
                                We never force you in to a situation you do not feel comfortable in.
                                You choose to accept our offer, you choose when you want to close, and you decide when you want to cash your check!</p>
                            <br/>

                            <h2>Get Started Right Away…</h2>
                            <p>Don’t hesitate to get in touch.
                                When you’re ready to sell your house fast for cash make sure you call us at <strong>(404) 793-4464</strong> or fill out our simple form below.
                                We are always looking to buy more houses.</p>
                        </div>
                    </div>
                </div>
                <Footer />
            </Wrapper >
        )
    }
}

export default AvoidMLSListing;
