import React from 'react'

export const Contacts = ({ contacts }) => {

    const ContactRow = (contact, index) => {

        return (
            <tr key={index} className='even'>
                <td> {index + 1} </td>
                <td>{contact.firstName}</td>
                <td>{contact.lastName}</td>
                <td>{contact.address}</td>
                <td>{contact.mobile}</td>
                <td>{contact.email}</td>
                <td>{contact.message}</td>
            </tr>
        )
    }

    const ContactTable = contacts.map((cust, index) => ContactRow(cust, index))

    const tableHeader = <thead className='bgvi'>
        <tr>
            <th>#</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Address</th>
            <th>Mobile</th>
            <th>Email</th>
            <th>Message</th>
        </tr>
    </thead>

    return (
        <table className="table table-striped table-bordered table-sm" id="contacts-xls">
            {tableHeader}
            <tbody>
                {ContactTable}
            </tbody>
        </table>
    )
}
