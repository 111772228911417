import React from 'react'

export const Sellers = ({ sellers }) => {

    const SellerRow = (seller, index) => {

        return (
            <tr key={index} className='even'>
                <td> {index + 1} </td>
                <td>{seller.firstName}</td>
                <td>{seller.lastName}</td>
                <td>{seller.address}</td>
                <td>{seller.email}</td>
                <td>{seller.mobile}</td>
            </tr>
        )
    }

    const SellerTable = sellers.map((cust, index) => SellerRow(cust, index));

    const tableHeader = <thead className='bgvi'>
        <tr>
            <th>#</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Address</th>
            <th>Email</th>
            <th>Mobile</th>
        </tr>
    </thead>

    return (
        <table className="table table-striped table-bordered table-sm" id="sellers-xls">
            {tableHeader}
            <tbody>
                {SellerTable}
            </tbody>
        </table>
    )
}
