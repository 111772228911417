import React from "react";
import './logoHeader.css';

function LogoHeader(props) {

  return (

    <div className="container-fluid bg-dark">
      <div className="row">
        <div className="col p-3">
          <a href="/"><img src={"/img/logo.png"} height="100"/></a>
        </div>
      </div>
    </div>

  )
}

export default LogoHeader;
