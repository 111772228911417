import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyCYCimi9EL0nMRV_17tl9ENVbPntOKM6_U",
    authDomain: "prominent-elite-ventures.firebaseapp.com",
    databaseURL: "https://prominent-elite-ventures.firebaseio.com",
    projectId: "prominent-elite-ventures",
    storageBucket: "prominent-elite-ventures.appspot.com",
    messagingSenderId: "1050319297910",
    appId: "1:1050319297910:web:08baaa6131b03890"
};
firebase.initializeApp(firebaseConfig);

export default firebase;
