import React, { Component } from "react";
import './homepage.css';
import Wrapper from "../../components/Wrapper";
import TopJumbotron from "../../components/Top-Jumbotron";
import BottomJumbotron from "../../components/Bottom-Jumbotron";
import Footer from "../../components/Footer";
import ReactPixel from "react-facebook-pixel";


class HomePage extends Component {

    componentDidMount() {

        ReactPixel.init("373240763620383", {}, { debug: true, autoConfig: false });
        ReactPixel.pageView();
        ReactPixel.fbq("track", "PageView");
    }

    render() {
        return (
            <Wrapper>
                <TopJumbotron />
                <div>
                    <div className="container-fluid">

                        <div className="row justify-content-md-center">
                            <div className="col">
                                <h1>
                                    Sell Your House Fast In The Atlanta Metro Area... <br />Without Paying Any Repairs or Closing Costs!
                                </h1>
                            </div>
                        </div>
                        <div className="row justify-content-md-center pt-4">
                            <div className="col-md-8">
                                <h5>We Are Your Local Atlanta Metro Area House Buyers. We Work Hard Every Day To Help Homeowners Just Like You To Sell Their House At A Fair Cash Price.
                                <a href="/getacashoffertoday"> Discover How Much You Could Get For Your House!</a>
                                </h5>
                            </div>
                        </div>

                        <div className="row justify-content-md-center pt-3">
                            <div className="left-box">

                                <p><b>Are you ready to sell your house? The team at Prominent Elite Ventures</b>, are excited to talk with you about buying your house at a fair cash
                                       price!</p>
                                <p>We are able to buy all types of houses in the Atlanta Metro Area regardless of the situation you are in.</p>
                                <p>It doesn’t matter if you are upside down on your mortgage, facing foreclosure, or going through a nasty divorce.
                                       If you are in any of these situations, we can still buy your house.</p>

                                <p>We have helped a number of home-owners sell their house even when they faced the worst of these situations.
                                   You can feel safe knowing that <b>Prominent Elite Ventures</b> will take care of the entire selling process.
                                       Making sure that you only have to worry about putting cash in your pocket.</p>

                            </div>

                            <div className="right-box custom-box box-height">
                                <h1>
                                    <p>How Can We Pay More?</p>
                                </h1>
                                <p>Learn How We Are Able to Pay More Cash For Your House, While At The Same Time Making The Process Quick & Hassle Free…</p>

                                <a className="btn bg-light" href="/getacashoffertoday" role="button">Click to Learn More</a>
                            </div>
                        </div>

                        <div className="row justify-content-md-center pt-3 bg-dark">
                            <div className="left-no-white-box">
                                <h3> WE MAKE SELLING A HOUSE EASY!</h3>
                                <p>No matter how bad your house looks, we can still buy for cash...
                                    When you sell to us you never have to worry about:</p>
                                <ul>
                                    <li>Dumping your hard earned money into repairing the house</li>
                                    <li>Dealing with inexperienced agents who just waste your time and leave your house under contract not allowing you to do anything with it</li>
                                    <li>Or trying to sell it yourself only to get overwhelmed by all the contracts, paperwork, and “tirekickers” wasting your time when they aren’t even serious buyers!</li>
                                </ul>
                                <p>We are experienced real estate investors and have countless ways for us to buy your house.
                                    This means that no matter what situation you find yourself in, we can always help.
                                    We are not looking to earn a commission from you.</p>
                                <p>We want to buy your house. When you sell to us you never have to worry about unsure buyers backing out at the last minute because of financing issues...
                                    We have the cash to buy your house today!</p>

                            </div>

                            <div className="accordion-box ml-1 mb-5 p-2">
                                <h3 style={{ color: "white" }}>WHY WE’RE BETTER</h3>
                                <div className="accordion" id="accordionExample">

                                    <div className="card">
                                        <div className="card-header custom-box" id="headingOne">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed text-white" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    <strong>Pay No Agent Fees</strong>
                                                </button>
                                            </h5>
                                        </div>

                                        <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                            <div className="card-body bg-white">
                                                Most home owners looking to sell their Atlanta home decide to list with an agent. They do this not knowing that there is a huge commission they have to pay once their house sells. When you sell your house through an agent you most likely have to pay a whopping 6% commission. That means if you sell a house for $100,000 you  will be losing a total of $6,000! When you sell to AMW Acquisitions you never have to pay a single cent in commissions. This means more cash in your pockets!
                                        </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header custom-box" id="headingTwo">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed text-white" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    <strong>Pay No Closing Costs</strong>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                            <div className="card-body">
                                                Not very many home owners know that closing costs can eat up a huge chunk of their profits. When you sell your house to us you never have to worry about pesky closing costs. We take care of all costs, including the ones that sneak their way in at closing.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header custom-box" id="headingThree">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed text-white" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    <strong>Pay No Repairs</strong>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                            <div className="card-body">
                                                A huge reason why so many home owners decide to sell to us is because they do not want to go through the hassle of paying to repair their house… or even spend the time to do it themselves. We buy houses in any condition, no matter how ugly they might be.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header custom-box" id="headingFour">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed text-white" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                    <strong>Close When You're Ready</strong>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                            <div className="card-body">
                                                When you sell to us you are not listing your house. We are here to BUY your house. This means you don’t have to sit around waiting for a buyer. You already found one. Selling to us means you can get your cash in as little as 7 DAYS! And if you’re not ready today, that’s perfectly fine. You choose when you want to sell your house.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-header custom-box" id="headingFive">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed text-white" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                    <strong>Top Dollar Offer</strong>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                            <div className="card-body">
                                                It is our mission to give our sellers a fair top dollar cash offer. We don’t want you to have to waste time looking around for the highest bidder. When you sell to us you can be confident that you are receiving the absolute best cash offer. And if our cash offer doesn’t work for you… we also are able to offer you creative term offers that puts even MORE CASH in your pocket. Get Your No-Obligation Offer Today!
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-md-center p-4">
                            <h1>We Are Not Your Ordinary Metro Atlanta House Buyers!</h1>
                        </div>

                        <div className="row justify-content-md-center">

                            <div className="left-box">
                                <img src={"/img/house-2.jpeg"} height="190" alt="Logo" />
                            </div>

                            <div className="left-box">
                                <div>
                                    <p>We have one goal here at Prominent Elite Ventures. To help our local community by offering a quick and hassle free home selling solution. Our process is simple. You submit your information or give us a call and we can get you a <a href="/getacashoffertoday"> FREE No-Obligation cash offer in as little as 24 hours!</a></p>

                                    <p>Once you choose to accept our offer, we’ll begin working immediately to close quickly on your house... <strong>(we can even buy your house in as little as 7 DAYS)</strong> The best part is that it doesn’t matter what condition your house is in. We’ve purchased houses that were almost falling apart, all the way to high end homes. There is no home we do not want to buy.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <BottomJumbotron />
                <Footer />
            </Wrapper >

        )
    }
}

export default HomePage;
