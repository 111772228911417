import React, { Component } from "react";
import FormSubmitted from "../../components/FormSubmitted";
import Firebase from "../../utils/Firebase";
import '../../components/jumbotron.css';
import Wrapper from "../../components/Wrapper";
import Footer from "../../components/Footer";
import ReactPixel from "react-facebook-pixel";


class CashOfferPage extends Component {

    componentDidMount() {

        ReactPixel.init("373240763620383", {}, { debug: true, autoConfig: false });
        ReactPixel.pageView();
        ReactPixel.fbq("track", "PageView");
    }

    state = {
        fname: "",
        lname: "",
        address: "",
        mobile: "",
        email: "",
        showSubmitted: false
    };

    onFocus = event => {

        this.setState({
            showSubmitted: false
        })
    }

    handleInputChange = event => {

        this.setState({ [event.target.name]: event.target.value });
    }

    handleFormSubmit = event => {
        event.preventDefault();

        event.target.className += " was-validated";

        if (event.target.checkValidity()) {

            Firebase
                .firestore()
                .collection('Sellers')
                .add({
                    firstName: this.state.fname,
                    lastName: this.state.lname,
                    address: this.state.address,
                    mobile: this.state.mobile,
                    email: this.state.email
                })
                .then(() => {

                    this.setState({
                        fname: "",
                        lname: "",
                        address: "",
                        mobile: "",
                        email: "",
                        showSubmitted: true
                    })
                })

            event.target.className -= " was-validated";

            ReactPixel.trackCustom('Cash_Offer_Form');
        }
    }


    render() {
        return (
            <Wrapper>
                <div className="row justify-content-md-center p-4">
                    <h2>Receive Your FREE No-Obligation Cash Offer In As Little As 24 Hours!</h2>
                </div>

                <div className="row justify-content-md-center mb-4">

                    <div className="col-md-6 offset-md-3" className="right-no-white-box">
                        <img src={"/img/get-cash-for-your-house.png"} height="305" alt="Logo" />
                    </div>

                    <div className="col-md-6 offset-md-3" className="formBackground box ml-4 mr-4">
                        <h3>We buy houses for cash fast!</h3>

                        <p>If you’ve been looking to sell a house fast in the Atlanta Metro Area,
                            give us a call at (404) 793-4464 or fill out our simple form below and we can give you a fast and fair cash offer.
                            What do you have to lose? Call us today!</p>

                        <div className="mt-4">
                            {this.state.showSubmitted ? <FormSubmitted /> : null}
                        </div>

                        <form className="needs-validation" noValidate onSubmit={this.handleFormSubmit}>
                            <div className="form-row mt-4">
                                <div className="form-group col-md-6">

                                    <input type="text"
                                        name="fname"
                                        value={this.state.fname}
                                        onChange={this.handleInputChange}
                                        onFocus={this.onFocus}
                                        className="form-control"
                                        id="inputFirstName"
                                        placeholder="First Name"
                                        required
                                    />
                                    <div className="invalid-feedback">
                                        Please provide your first name.
                                        </div>
                                </div>

                                <div className="form-group col-md-6">
                                    <input type="text"
                                        name="lname"
                                        value={this.state.lname}
                                        onChange={this.handleInputChange}
                                        onFocus={this.onFocus}
                                        className="form-control"
                                        id="inputLastName"
                                        placeholder="Last Name"
                                        required
                                    />
                                    <div className="invalid-feedback">
                                        Please provide your last name.
                                        </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <input type="text"
                                    name="address"
                                    value={this.state.address}
                                    onChange={this.handleInputChange}
                                    onFocus={this.onFocus}
                                    className="form-control"
                                    id="inputAddress"
                                    placeholder="Property Address"
                                    required
                                />
                                <div className="invalid-feedback">
                                    Please provide the address that is for sale.
                                    </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <input type="text"
                                        name="mobile"
                                        value={this.state.mobile}
                                        onChange={this.handleInputChange}
                                        onFocus={this.onFocus}
                                        className="form-control"
                                        id="inputMobile"
                                        placeholder="Mobile Number"
                                        required
                                    />
                                    <div className="invalid-feedback">
                                        Please provide your mobile number.
                                        </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <input type="email"
                                        name="email"
                                        value={this.state.email}
                                        onChange={this.handleInputChange}
                                        onFocus={this.onFocus}
                                        className="form-control"
                                        id="inputEmail"
                                        placeholder="E-mail Address"
                                        required
                                    />
                                    <div className="invalid-feedback">
                                        Please provide your email address.
                                        </div>
                                </div>
                            </div>
                            <button type="submit" className="needs-validation"
                                className="btn btn-custom">
                                Get A Fair Cash Offer Now!
                                    </button>
                            <small className="form-text mt-3 text-primary">
                                We'll never share your email or mobile number with anyone.
                                </small>
                        </form>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row justify-content-md-center bg-dark">

                        <div className="col-md-8 text-white pt-4 pb-4">
                            <p>Are you ready to <strong>sell your house fast for cash?</strong> Want to skip the headaches of selling a house and just simply put more cash in your pocket?
                                We here at Prominent Elite Ventures buy houses in the Atlanta Metro Area every single month and are looking to buy even more!
                                 <strong> We have helped several people in our area sell their house for cash</strong> and the best part is we do all the work for you.</p>

                            <p>When you sell to us you don’t ever have to worry about listing your house, paying for repairs or doing them yourselves,
                                letting your house sit on the market, or risk going into closing just to find out the buyer can’t afford to buy the home.
                                We don’t list your house. <strong>WE BUY IT</strong>. And we pay cash when we buy.</p>

                            <h2>No matter what situation you find yourself facing, we can help...</h2>
                            <p>Going through a divorce? We actually make it easier to sell a house.
                                Avoid the time it takes having to list the house on the market.
                                Skip all the unnecessary arguments of who, what, where, when, and how to sell the house.
                                Give us a call and we can buy your house quickly and pain free!</p>

                            <p>Facing foreclosure? Save yourself from the headaches of dealing with those pesky banks.
                                We have helped numerous people avoid foreclosure,
                                and have even had some situations where they’ve <strong>walked away with cash in their pocket</strong>.</p>

                            <p>Tired of being a landlord? Trust us we understand.
                                Having to constantly deal with tenants and repairing your rentals is a huge pain.
                                Avoid the hassle and get cash for your house.</p>

                            <p>Recently inherited a house? Skip the hassle of trying to sell...
                                We have bought several inherited homes and are always willing to help.</p>

                            <p>As you can see there is not situation we cannot help with.
                                If you have a house to sell, we are definitely the ones to buy!
                                Give us a call at <strong>(404) 793-4464</strong> or fill out our simple form below.</p>
                        </div>
                    </div>

                    <div className="row justify-content-md-center pt-3 pb-4 mt-4">

                        <div className="col-md-6 offset-md-3" className="formBackground box">
                            <h3>We buy houses for cash fast!</h3>

                            <div className="mt-4">
                                {this.state.showSubmitted ? <FormSubmitted /> : null}
                            </div>

                            <form className="needs-validation" noValidate onSubmit={this.handleFormSubmit}>
                                <div className="form-row mt-4">
                                    <div className="form-group col-md-6">

                                        <input type="text"
                                            name="fname"
                                            value={this.state.fname}
                                            onChange={this.handleInputChange}
                                            onFocus={this.onFocus}
                                            className="form-control"
                                            id="inputFirstName"
                                            placeholder="First Name"
                                            minLength="3"
                                            maxLength="20"
                                            required
                                        />
                                        <div className="invalid-feedback">
                                            Please provide your first name.
                                        </div>
                                    </div>

                                    <div className="form-group col-md-6">
                                        <input type="text"
                                            name="lname"
                                            value={this.state.lname}
                                            onChange={this.handleInputChange}
                                            onFocus={this.onFocus}
                                            className="form-control"
                                            id="inputLastName"
                                            placeholder="Last Name"
                                            required
                                        />
                                        <div className="invalid-feedback">
                                            Please provide your last name.
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input type="text"
                                        name="address"
                                        value={this.state.address}
                                        onChange={this.handleInputChange}
                                        onFocus={this.onFocus}
                                        className="form-control"
                                        id="inputAddress"
                                        placeholder="Property Address"
                                        required
                                    />
                                    <div className="invalid-feedback">
                                        Please provide the address that is for sale.
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <input type="text"
                                            name="mobile"
                                            value={this.state.mobile}
                                            onChange={this.handleInputChange}
                                            onFocus={this.onFocus}
                                            className="form-control"
                                            id="inputMobile"
                                            placeholder="Mobile Number"
                                            required
                                        />
                                        <div className="invalid-feedback">
                                            Please provide your mobile number.
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <input type="email"
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.handleInputChange}
                                            onFocus={this.onFocus}
                                            className="form-control"
                                            id="inputEmail"
                                            placeholder="E-mail Address"
                                            required
                                        />
                                        <div className="invalid-feedback">
                                            Please provide your email address.
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" className="needs-validation"
                                    className="btn btn-custom">
                                    Get A Fair Cash Offer Now!
                                    </button>
                                <small className="form-text mt-3 text-primary">
                                    We'll never share your email or mobile number with anyone.
                                </small>
                            </form>
                        </div>
                    </div>
                </div>

                <Footer />
            </Wrapper >
        )
    }
}

export default CashOfferPage;
