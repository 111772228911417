import React, { Component } from "react";
import { Sellers } from './Excel/Sellers'
import { Buyers } from './Excel/Buyers'
import { Header } from './Excel/Header'
import { Contacts } from './Excel/Contacts'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Firebase from "../../utils/Firebase";


class ContactTable extends Component {

  state = {
    fileName: "Sellers",
    sellers: [],
    buyers: [],
    contacts: []
  }

  componentDidMount() {

    let sellers = [];
    let buyers = [];
    let contacts = [];

    Firebase
      .firestore()
      .collection('Sellers')
      .get()
      .then(snapshot => {
        snapshot.docs.forEach(doc => {
          sellers.push(doc.data())
        })
        this.setState({ sellers });
      });

    Firebase
      .firestore()
      .collection('Buyers')
      .get()
      .then(snapshot => {
        snapshot.docs.forEach(doc => {
          buyers.push(doc.data());
        })
        this.setState({ buyers });
      });

    Firebase
      .firestore()
      .collection('Contacts')
      .get()
      .then(snapshot => {
        snapshot.docs.forEach(doc => {
          contacts.push(doc.data());
        })
        this.setState({ contacts });
      });

      this.authListener();
  }

  authListener = () => {
    Firebase.auth().signOut().then(function () {
      // Sign-out successful.
    }).catch(function (error) {
      // An error happened.
    });
  }

  sellers = () => {
    return this.state.sellers
  }

  buyers = () => {
    return this.state.buyers
  }

  contacts = () => {
    return this.state.contacts
  }

  handleClickChange = event => {

    this.setState({
      fileName: event.target.id
    });
  }

  render() {

    let excelFile;

    if (this.state.fileName === "Sellers") {
      excelFile = <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button"
        table="sellers-xls"
        filename="Sellers"
        sheet="Sellers"
        buttonText="Download" />
    }
    else if (this.state.fileName === "Buyers") {
      excelFile = <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button"
        table="buyers-xls"
        filename="Buyers"
        sheet="Buyers"
        buttonText="Download" />
    }
    else {
      excelFile = <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button"
        table="contacts-xls"
        filename="Contacts"
        sheet="Contacts"
        buttonText="Download" />
    }

    return (
      <>
        <Header />
        <div className="row justify-content-md-center mt-4 mb-4">

          <div className="col-md-8 ml-2 mr-2">
            <nav>
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <a className="nav-item nav-link active" id="Sellers" data-toggle="tab" href="#nav-sellers" role="tab" aria-controls="nav-sellers" aria-selected="true"
                  onClick={this.handleClickChange}>Sellers</a>
                <a className="nav-item nav-link" id="Buyers" data-toggle="tab" href="#nav-buyers" role="tab" aria-controls="nav-buyers" aria-selected="false"
                  onClick={this.handleClickChange}>Buyers</a>
                <a className="nav-item nav-link" id="Contacts" data-toggle="tab" href="#nav-contacts" role="tab" aria-controls="nav-contacts" aria-selected="false"
                  onClick={this.handleClickChange}>Contacts</a>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              <div className="tab-pane fade active show mt-3" id="nav-sellers" role="tabpanel" aria-labelledby="nav-home-tab">
                <Sellers sellers={this.sellers()} />
              </div>
              <div className="tab-pane fade mt-3" id="nav-buyers" role="tabpanel" aria-labelledby="nav-profile-tab">
                <Buyers buyers={this.buyers()} />
              </div>
              <div className="tab-pane fade mt-3" id="nav-contacts" role="tabpanel" aria-labelledby="nav-contact-tab">
                <Contacts contacts={this.contacts()} />
              </div>
            </div>
          </div>
          <div className="col-md-1 ml-2">
            {excelFile}
          </div>
        </div>
      </>
    );
  }
}

export default ContactTable;
