import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import Firebase from '../../utils/Firebase';
import Authenticate from '../../utils/Authenticate';

class Admin extends Component {

  state = {
    email: '',
    password: ''
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    });
  }

  handleSubmit = (e) => {
    e.preventDefault()

    // Login to Firebase
    Firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password)
      .then((e) => {

        Authenticate.login(() => {
          this.props.history.push("/contactTable");
        })
      })
      .catch((error) => {
        if (error) {
          console.log(error);
        }
      })
    }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-sm"></div>
          <div className="col-3 text-center vertical-center">
            <div className="mt-5">
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <input type="email" className="form-control" id="email" onChange={this.handleChange} placeholder="Enter email" />
                </div>
                <div className="form-group">
                  <input type="password" className="form-control" id="password" onChange={this.handleChange} placeholder="Password" />
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
              </form>
            </div>
          </div>
          <div className="col-sm"></div>
        </div>
      </div>
    )
  }
}

export default withRouter(Admin);
